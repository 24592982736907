import 'quill/dist/quill.snow.css'
import 'css/app/style.css'

import 'form-request-submit-polyfill'

import { Popover, Modal } from 'bootstrap'
 
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Turbo from "@hotwired/turbo"
import { StreamActions } from '@hotwired/turbo'

import { eventDispatcher } from '@/libs/json-event'


StreamActions.closeModal = function () {
    const targetModal = document.getElementById(this.getAttribute('target'))
    
    if (targetModal) {
        const modalInstance = Modal.getInstance(targetModal)
       
        if (modalInstance) {
            modalInstance.hide()
        }
    }
}

document.addEventListener('click', (e) => {
    if(e.target.hasAttribute('data-inner-click')) {
        e.stopImmediatePropagation()
        e.target.dispatchEvent(new Event('inner:click'))
    }
}, true)

document.addEventListener('turbo:before-fetch-request', (e) => {
    if (e.detail.fetchOptions.headers['Turbo-Frame']) {
        e.detail.fetchOptions.headers.Accept += ', application/vnd.json-event.json'
    }
})

document.addEventListener('turbo:before-fetch-response', (e) => {
    const contentType = e.detail.fetchResponse.response.headers.get('content-type')

    if (contentType == 'application/vnd.json-event.json') {
        e.preventDefault()
        e.detail.fetchResponse.response.json().then((data) => {
            eventDispatcher.create(data).dispatch()
        })
    }
})

document.addEventListener('turbo:click', (e) => {
    const activeModal = document.querySelector('.modal.show')
    
    if (activeModal) {
        const modalInstance = Modal.getInstance(activeModal)
        activeModal.classList.remove('fade')
        
        if (modalInstance) {
            modalInstance.hide()
            const backdrop = document.querySelector('.modal-backdrop')
            if (backdrop) {
                backdrop.remove()
            }
        }
        activeModal.classList.add('fade')
        document.body.classList.remove('modal-open')
        document.body.style = ''

    }

    document.querySelectorAll('[data-controller=popover]').forEach(function(popover) {
        const popoverInstance = Popover.getInstance(popover)
        if (popoverInstance) {
            popoverInstance.hide()
        }
    })

    document.body.click()
})

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

