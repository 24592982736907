import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { round } from '@/utils/common'
import { Color, CHART_COLORS } from '@/utils/color'


const DEFAULT_COLOR = new Color(CHART_COLORS.teal).alpha(0.85).rgbString()


export default class extends Controller {
    static targets = ['ctx']
    static values = {
        type: String,
        chartData: Object,
        charted: String,
        customColors: Array,
        colorizeByAxis: String,
        yScaleLabel: String,
        beginAtZero: Boolean,
        suggestedMin: Number,
        suggestedMax: Number,
        stepSize: Number,
        showDataLabels: { type: Boolean, default: true },
        stacked: { type: Boolean, default: false },
        indexAxis: { type: String, default: 'x'},
        maxLabelLength: { type: Number, default: 15 },
        xScaleTicks: { type: Boolean, default: true },
        yScaleTicks: { type: Boolean, default: true },
        yScaleGrid: { type: Boolean, default: true },
    }

    initialize() {
        this.initializeChart()
    }
    
    initializeChart() {
        const chartData = this.getChartData()

        if (chartData.isHorizontal) {
            const chartHeight = this.getHorizontalChartHeight(chartData)
            this.ctxTarget.parentNode.style.height = chartHeight
            this.ctxTarget.parentNode.style.maxHeight = chartHeight
        }

        const datasets = Object.values(chartData.datasets).map((dataset) => {
            return {
                label: dataset.label,
                backgroundColor: dataset.colors,
                borderWidth: 0,
                maxBarThickness: 40,
                categoryPercentage: chartData.isHorizontal ? 0.6 : 0.8,
                data: dataset.data
            }
        })
        
        this.chart = new Chart(this.ctxTarget, {
            type: this.typeValue,
            plugins: this.showDataLabelsValue ? [ChartDataLabels] : [],
            data: {
                labels: chartData.labels,
                datasets: datasets,
            },
            options: {
                indexAxis: this.indexAxisValue,
                responsive: true,
                maintainAspectRatio: false,
                scales: this.getScalesConfig(chartData),
                elements: { 
                    point: { 
                        radius: 0,
                        hitRadius: 5, 
                        hoverRadius: 3
                    } 
                },
                plugins: {
                    title: this.getChartTitle(chartData),
                    legend: {
                        display: false
                    },
                    tooltip: {
                        bodyFont: {
                            weight: 'bold',
                        },
                        footerFont: {
                            weight: 'normal',
                        },
                        position: 'nearest',
                        callbacks: {
                            title: (tooltipItems) => {
                                return ''
                            },
                            label: (tooltipItem,) => {
                                let label = tooltipItem.label
                                if (typeof label == 'string' && label.length > 70) {
                                    label = label.substring(0, 140) + '…'
                                }
                                return label
                            },
                            footer:  (tooltipItems) => {
                                const labels = [tooltipItems[0].dataset.label + ': ' + tooltipItems[0]['formattedValue']]
                                const index = tooltipItems[0].dataIndex
                                
                                Object.keys(chartData.extraData).forEach(key => {
                                    const item = chartData.extraData[key];
                                    labels.push(item.label + ': ' + item.data[index])
                                })
                                return labels
                            }
                        }
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'start',
                        color: (context) => {
                            let bgColor = context.dataset.backgroundColor
                            if (Array.isArray(bgColor)) {
                                bgColor = bgColor[context.dataIndex]
                            }
                            return new Color(bgColor).isDark() ? '#ffffff' : undefined
                        },
                        font: {
                            weight: 'bold'
                        },
                        offset: 0
                    }
                }
            }
        })
    }

    getChartData() {
        const parsed = this.chartDataValue
        const labels = []
        const datasets = {}
        const extraData = {}
        const chartType = this.typeValue
        const customColors = this.customColors
        const numColors = customColors ? customColors.length : 0
        const colorizeByX = this.colorizeByAxisValue == 'x' && customColors
        const colorizeByY = this.colorizeByAxisValue == 'y' && customColors
        const colors = []
        
        parsed.datasets.forEach((dataset) => {
            const item = {
                label: dataset.label,
                data: []
            }

            if (dataset.charted !== false) {
                item.colors = dataset.color ? dataset.color : colors
                datasets[dataset.key] = item
            }
            else {
                extraData[dataset.key] = item
            }
        })

        parsed.data.forEach(item => {
            Object.keys(item).forEach(key => {
                const value = item[key];

                if (key == 'label') {
                    labels.push(value)

                    if (colorizeByX) {
                        const offset = labels[0] == 0 ? 0 : 1
                        colors.push(customColors[Math.floor(parseFloat(value)-offset)])
                    }
                }
                else if (key in datasets) {
                    if (customColors) {
                        if (colorizeByY) {
                            colors.push(customColors[Math.floor(parseFloat(value)-1)])
                        } 
                        else if (!colorizeByX) {
                            colors.push(customColors[datasets[key].data.length % numColors])
                        }
                    } else {
                        colors.push(DEFAULT_COLOR)
                    }
                    
                    datasets[key].data.push(round(value, 2))
                }
                else if (key in extraData) {
                    extraData[key].data.push(round(value, 2))
                }
            })
        })

        return {
            type: chartType,
            title: parsed.title,
            labels: labels,
            datasets: datasets,
            extraData: extraData,
            isHorizontal: chartType == 'bar' && this.indexAxisValue == 'y' ? true : false,
        }
    }

    getHorizontalChartHeight(chartData) {
        let height = 24,
            numRows = chartData.labels.length

        if (numRows <= 20) {
            height = 32;
        }
        if (numRows <= 10) {
            height = 40;
        }

        return (100 + numRows * height) + 'px'
    }

    getChartTitle(chartData) {
        if (chartData.title) {
            return {
                display: true,
                text: chartData.title,
                font: {
                    size: 14
                }
            }
        }
        return {}
    }

    getScalesConfig(chartData) {
        if (this.typeValue != 'pie') {
            if (chartData.isHorizontal) {
                return {
                    x: this.getVerticalAxesConfig(chartData),
                    y: this.getHorizontalAxesConfig(chartData)
                }
            } else {
                return {
                    x: this.getHorizontalAxesConfig(chartData),
                    y: this.getVerticalAxesConfig(chartData)
                }
            }
        }
        return {}
    }

    getHorizontalAxesConfig(chartData) {
        return {
            type: 'category',
            stacked: this.stackedValue,
            grid: {
                display: false
            },
            ticks: {
                display: this.xScaleTicksValue,
                callback: (value, index, ticks) => {
                    let label = chartData.labels[index]
                    if (this.maxLabelLengthValue && label.length > this.maxLabelLengthValue) {
                        return label.substring(0, this.maxLabelLengthValue) + '…'
                    }
                    return label
                }
            }
        }
    }

    getVerticalAxesConfig(chartData) {
        const config = {
            type: 'linear',
            position: 'left',
            grid: {
                display: this.yScaleGridValue,
            },
            border: {
                display: false,
            },
            ticks: {
                display: this.yScaleTicksValue,
                autoSkip: false,
                padding: 10
            },
            title: {
                display: this.hasYScaleLabelValue ? this.yScaleLabelValue !== 'null' : true,
                text: this.hasYScaleLabelValue ? this.yScaleLabelValue : chartData.datasets[Object.keys(chartData.datasets)[0]].label,
                padding: 20,
            }
        }

        if(this.hasBeginAtZeroValue) {
            config.beginAtZero = this.beginAtZeroValue
        }

        if(this.hasSuggestedMinValue) {
            config.suggestedMin = this.suggestedMaxValue
        }

        if(this.hasSuggestedMaxValue) {
            config.suggestedMax = this.suggestedMinValue
        }

        if(this.hasStepSizeValue) {
            config.ticks.stepSize = this.stepSizeValue
        }
        return config
    }

    get customColors() {
        if (this.hasCustomColorsValue && this.customColorsValue.length > 0) {
            return this.customColorsValue.map((color) => new Color(color).rgbString())
        }
        return null
    }
}