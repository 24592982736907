function truncateChars(str, length) {
    return str.length > length ? `${str.substring(0, length-1)}…` : str
}

function trimString(str, ch) {
    var start = 0, 
    end = str.length;
    
    while(start < end && str[start] === ch)
        ++start;
    
    while(end > start && str[end - 1] === ch)
        --end;
    
    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

function round(number, precision = 0) {
    const func = Math.round;
    precision = precision == null ? 0 : Math.min(precision, 292);
    
    if (precision && isFinite(number)) {
        let [base, exponent] = (number + 'e').split('e');
        let value = func(+(`${base}e${+exponent + precision}`));
        
        [base, exponent] = (value + 'e').split('e');
        return +(base + 'e' + (+exponent - precision));
    }
    
    return func(number);
}

function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

function merge(target, ...sources) {
    if (!sources.length) return target;
    
    sources.forEach(source => {
        if (!isObject(source)) return;
        
        Object.keys(source).forEach(key => {
            const targetValue = target[key];
            const sourceValue = source[key];
            
            if (Array.isArray(sourceValue)) {
                // Replace array, do not merge arrays
                target[key] = sourceValue.slice();
            } else if (isObject(sourceValue)) {
                // If the target does not have this key, or the target value is not an object, initialize it as an empty object
                if (!targetValue || !isObject(targetValue)) {
                    target[key] = {};
                }
                // Recursive merge
                merge(target[key], sourceValue);
            } else {
                // For primitive values or other non-plain objects (like Date, Set, etc.), just replace the value
                target[key] = sourceValue;
            }
        });
    })
    
    return target;
}


export {
    isObject,
    round,
    merge,
    trimString,
    truncateChars,
}
